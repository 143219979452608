import { Storage, Drivers } from "@ionic/storage";

let storage: Storage;

export const createStore = (name = "__storage") => {
  storage = new Storage({
    name,
    driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
  });

  storage.create();
};

export const set = (key: string, value: string) => {
  storage.set(key, value);
};

export const get = async (key: string) => {
  const value = await storage.get(key);
  return value;
};

export const remove = async (key: string) => {
  await storage.remove(key);
};

export const clear = async () => {
  await storage.clear();
};
