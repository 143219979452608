import {
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { bulb, closeOutline, pricetagOutline } from "ionicons/icons";
// import { useStoreState } from "pullstate";
import { List } from "models/List";
// import { ProductStore } from "store";
// import { getAllProduct } from "store/Selectors";

import styles from "./ListModal.module.scss";

interface Props {
  dismiss: () => void;
  list: List;
}

export const ListModal = ({ dismiss, list }: Props) => {
  // const product = useStoreState(ProductStore, getAllProduct);

  return (
    <IonPage className="itemList-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Items</IonTitle>

          <IonButtons slot="end">
            <IonButton color="primary" onClick={dismiss}>
              <IonIcon icon={closeOutline} />
              {/* Leave Room */}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className={styles.modal}>
        <IonGrid className="ion-padding-start ion-padding-end ion-margin-start ion-margin-end">
          <IonRow>
            <IonCol size="12">
              <div className={styles.cardTitle}>
                <IonIcon color="white" icon={bulb} />
                <IonCardSubtitle color="primary">
                  {list.category.name}
                </IonCardSubtitle>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className={styles.itemListTitle}>
            <IonCol size="12">
              <h1>{list.title}</h1>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12">
              <div className={styles.detailCount}>
                <IonIcon icon={pricetagOutline} color="primary" />
                <span>{list.products.length} Products</span>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className={styles.itemListProducts}>
            {list.products.map((product, index) => {
              return (
                <IonCol className={styles.productContainer}>
                  <div
                    key={`product_${index}`}
                    className={`${styles.itemListProduct} ${
                      index === 0 && styles.activeProduct
                    }`}
                  >
                    <img src={product.image} alt="avatar" />
                  </div>
                  <p>{product.name.split(" ")[0]}</p>
                </IonCol>
              );
            })}
          </IonRow>

          <IonRow>
            <IonCol size="12">
              <div className={styles.detailCount}>
                <IonIcon icon={pricetagOutline} color="primary" />
                <span>{list.products.length} Products</span>
              </div>
            </IonCol>
          </IonRow>

          <IonRow className={styles.itemListProducts}>
            {list.products.map((product, index) => {
              return (
                <IonCol
                  size="3"
                  className={`${styles.productContainer} ${styles.audienceContainer}`}
                >
                  <div
                    key={`product_${index}`}
                    className={styles.itemListProduct}
                  >
                    <img src={product.image} alt="avatar" />
                  </div>
                  <p>{product.name.split(" ")[0]}</p>
                </IonCol>
              );
            })}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
