import { Product } from "models/Product";
import { Category } from "models/Category";
import { List } from "models/List";

export const products = (): Product[] => [
  {
    name: "Toolbox",
    image: "/icons/tool-box.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Toolbox",
    image: "/icons/tool-box-1.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Gardening Tools",
    image: "/icons/gardening.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Shovel",
    image: "/icons/shovel.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Sketch",
    image: "/icons/sketch.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Grocery",
    image: "/icons/vegetable.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Automotive",
    image: "/icons/steering-wheel.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Sports",
    image: "/icons/sports.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Laptop",
    image: "/icons/laptop.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Jewelry",
    image: "/icons/jewelry.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Helmet",
    image: "/icons/helmet.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Baby",
    image: "/icons/feeding-bottle.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Clothes",
    image: "/icons/clothes.png",
    url: "https://github.com/openwishlist",
  },
  {
    name: "Clothes",
    image: "/icons/clothes-hanger.png",
    url: "https://github.com/openwishlist",
  },
];

interface Props {
  amount: number;
}
export const getProduct = ({ amount }: Props) => {
  const tempProduct: Product[] = [...products()];
  return tempProduct.sort(() => Math.random() - Math.random()).slice(0, amount);
};

export const categories = (): Category[] => [
  {
    id: 1,
    name: "Appliances",
  },
  {
    id: 2,
    name: "Tools & Home Improvement",
  },
  {
    id: 3,
    name: "Arts, Crafts, & Sewing",
  },
  {
    id: 4,
    name: "Home & Kitchen",
  },
  {
    id: 5,
    name: "Automotive Parts & Accessories",
  },
  {
    id: 6,
    name: "Clothing, Shoes and Jewelry",
  },
  {
    id: 7,
    name: "Baby",
  },
  {
    id: 8,
    name: "Computers & Electronics",
  },
];

export const lists = (): List[] => [
  {
    id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b5",
    title: "Appliances to buy for new home",
    date: "29th Oct 2021",
    time: "5:00PM",
    state: {
      pinned: true,
    },
    owner: {
      id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b6",
      name: "John Doe",
      username: "johndoe",
    },
    products: [
      {
        name: "Toolbox",
        image: "/icons/tool-box.png",
        url: "https://github.com/openwishlist",
        keywords: "Toolbox,Spanner,Wrench",
      },
      {
        name: "Toolbox",
        image: "/icons/tool-box-1.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Gardening Tools",
        image: "/icons/gardening.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Shovel",
        image: "/icons/shovel.png",
        url: "https://github.com/openwishlist",
      },
    ],
    category: {
      id: 1,
      name: "Appliances",
    },
  },
  {
    id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b7",
    title: "Tools and supplies for hobby",
    date: "29th Oct 2021",
    time: "5:00PM",
    state: {
      pinned: false,
    },
    owner: {
      id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b6",
      name: "John Doe",
      username: "johndoe",
    },
    products: [
      {
        name: "Toolbox",
        image: "/icons/tool-box.png",
        url: "https://github.com/openwishlist",
        keywords: "Toolbox,Spanner,Wrench",
      },
      {
        name: "Sketch",
        image: "/icons/sketch.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Gardening Tools",
        image: "/icons/gardening.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Shovel",
        image: "/icons/shovel.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Sports",
        image: "/icons/sports.png",
        url: "https://github.com/openwishlist",
      },
    ],
    category: {
      id: 2,
      name: "Arts, Crafts, & Sewing",
    },
  },
  {
    id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206bo9",
    title: "New bike safety gears to buy",
    date: "29th Oct 2021",
    time: "5:00PM",
    state: {
      pinned: false,
    },
    owner: {
      id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b6",
      name: "John Doe",
      username: "johndoe",
    },
    products: [
      {
        name: "Toolbox",
        image: "/icons/tool-box.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Automotive",
        image: "/icons/steering-wheel.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Helmet",
        image: "/icons/helmet.png",
        url: "https://github.com/openwishlist",
        keywords: "Helmet",
      },
      {
        name: "Sports",
        image: "/icons/sports.png",
        url: "https://github.com/openwishlist",
        keywords: "Gloves",
      },
    ],
    category: {
      id: 3,
      name: "Automotive Parts & Accessories",
    },
  },
  {
    id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206ao9",
    title: "New sorted laptops to buy one",
    date: "29th Oct 2021",
    time: "5:00PM",
    state: {
      pinned: false,
    },
    owner: {
      id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b6",
      name: "John Doe",
      username: "johndoe",
    },
    products: [
      {
        name: "Toolbox",
        image: "/icons/tool-box.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Automotive",
        image: "/icons/steering-wheel.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Helmet",
        image: "/icons/helmet.png",
        url: "https://github.com/openwishlist",
        keywords: "Helmet",
      },
      {
        name: "Sports",
        image: "/icons/sports.png",
        url: "https://github.com/openwishlist",
        keywords: "Gloves",
      },
    ],
    category: {
      id: 3,
      name: "Automotive Parts & Accessories",
    },
  },
  {
    id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206co9",
    title: "Kids school supplies",
    date: "29th Oct 2021",
    time: "5:00PM",
    state: {
      pinned: false,
    },
    owner: {
      id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b6",
      name: "John Doe",
      username: "johndoe",
    },
    products: [
      {
        name: "Toolbox",
        image: "/icons/tool-box.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Automotive",
        image: "/icons/steering-wheel.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Helmet",
        image: "/icons/helmet.png",
        url: "https://github.com/openwishlist",
        keywords: "Helmet",
      },
      {
        name: "Sports",
        image: "/icons/sports.png",
        url: "https://github.com/openwishlist",
        keywords: "Gloves",
      },
    ],
    category: {
      id: 3,
      name: "Automotive Parts & Accessories",
    },
  },
  {
    id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206do9",
    title: "Baby care and monthy supplies to buy",
    date: "29th Oct 2021",
    time: "5:00PM",
    state: {
      pinned: false,
    },
    owner: {
      id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b6",
      name: "John Doe",
      username: "johndoe",
    },
    products: [
      {
        name: "Toolbox",
        image: "/icons/tool-box.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Automotive",
        image: "/icons/steering-wheel.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Helmet",
        image: "/icons/helmet.png",
        url: "https://github.com/openwishlist",
        keywords: "Helmet",
      },
      {
        name: "Sports",
        image: "/icons/sports.png",
        url: "https://github.com/openwishlist",
        keywords: "Gloves",
      },
    ],
    category: {
      id: 3,
      name: "Automotive Parts & Accessories",
    },
  },
  {
    id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206eo9",
    title: "Monthy home groceries",
    date: "29th Oct 2021",
    time: "5:00PM",
    state: {
      pinned: false,
    },
    owner: {
      id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b6",
      name: "John Doe",
      username: "johndoe",
    },
    products: [
      {
        name: "Toolbox",
        image: "/icons/tool-box.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Automotive",
        image: "/icons/steering-wheel.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Helmet",
        image: "/icons/helmet.png",
        url: "https://github.com/openwishlist",
        keywords: "Helmet",
      },
      {
        name: "Sports",
        image: "/icons/sports.png",
        url: "https://github.com/openwishlist",
        keywords: "Gloves",
      },
    ],
    category: {
      id: 3,
      name: "Automotive Parts & Accessories",
    },
  },
  {
    id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206fo9",
    title: "New cloths to buy",
    date: "29th Oct 2021",
    time: "5:00PM",
    state: {
      pinned: false,
    },
    owner: {
      id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b6",
      name: "John Doe",
      username: "johndoe",
    },
    products: [
      {
        name: "Toolbox",
        image: "/icons/tool-box.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Automotive",
        image: "/icons/steering-wheel.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Helmet",
        image: "/icons/helmet.png",
        url: "https://github.com/openwishlist",
        keywords: "Helmet",
      },
      {
        name: "Sports",
        image: "/icons/sports.png",
        url: "https://github.com/openwishlist",
        keywords: "Gloves",
      },
    ],
    category: {
      id: 3,
      name: "Automotive Parts & Accessories",
    },
  },
  {
    id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206go9",
    title: "New bike safety gears to buy",
    date: "29th Oct 2021",
    time: "5:00PM",
    state: {
      pinned: false,
    },
    owner: {
      id: "ada8ae17-0a4a-4e3e-b0bc-6342e0c206b6",
      name: "John Doe",
      username: "johndoe",
    },
    products: [
      {
        name: "Toolbox",
        image: "/icons/tool-box.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Automotive",
        image: "/icons/steering-wheel.png",
        url: "https://github.com/openwishlist",
      },
      {
        name: "Helmet",
        image: "/icons/helmet.png",
        url: "https://github.com/openwishlist",
        keywords: "Helmet",
      },
      {
        name: "Sports",
        image: "/icons/sports.png",
        url: "https://github.com/openwishlist",
        keywords: "Gloves",
      },
    ],
    category: {
      id: 3,
      name: "Automotive Parts & Accessories",
    },
  },
];
