import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonCardSubtitle,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonImg,
  IonInput,
  IonItem,
  IonList,
  IonTextarea,
  IonLabel,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonChip,
  IonSpinner,
} from "@ionic/react";
import {
  addOutline,
  closeOutline,
  send,
  close as closeIcon,
  star,
  starHalf,
} from "ionicons/icons";
import { Product } from "models/Product";
import axios from "axios";
import { Offer, Review } from "schema-dts";
import { encode } from "utils/UrlEncoder";

import styles from "./AddProductModal.module.scss";
import { useState } from "react";
import { ENV } from "env/env";

interface Props {
  close: () => void;
  items: Product[];
}

export const AddProductModal = ({ close, items }: Props) => {
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>();

  const [product, setProduct] = useState<Product>();
  const [offer, setOffer] = useState<Offer>();

  const [description, setDescription] = useState<string>();
  const [rating, setRating] = useState<number>();
  const [tags, setTags] = useState<string[]>();

  const getProduct = () => {
    if (url !== undefined) {
      setLoading(true);
      let params = "";
      if (ENV.PREVIEW_ADD_REFRESH) {
        params = "?refresh=true";
      }
      axios.get(ENV.PREVIEW_ENDPOINT + encode(url) + params).then((result) => {
        let productOffer: Offer;
        setProduct(result?.data?.data);
        if (Array.isArray(result?.data?.data.schema?.offers)) {
          productOffer = result?.data?.data.schema?.offers[0];
        } else {
          productOffer = result?.data?.data.schema?.offers;
        }

        if (result?.data?.data?.keywords !== undefined) {
          setTags(result?.data?.data?.keywords.trim().split(","));
        }
        console.log(productOffer?.review);
        if (productOffer?.review !== undefined) {
          // const value = productOffer?.review
          // console.log(parseInt(value?.reviewRating?.ratingValue.toString()))
          // setRating(parseInt(value?.reviewRating?.ratingValue.toString()))
          setRating(4);
        }

        setOffer(productOffer);
        setLoading(false);
      });
    }
  };

  return (
    <IonPage className="itemList-modal">
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Product</IonTitle>

          <IonButtons slot="end">
            <IonButton color="primary" onClick={close}>
              <IonIcon icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className={styles.modal}>
        <IonGrid className="ion-padding-start ion-padding-end ion-margin-start ion-margin-end">
          <IonRow>
            <IonCol size="12">
              <IonItem>
                <IonInput
                  type="url"
                  value={url}
                  placeholder="Enter url of product to add"
                  onIonChange={(e) =>
                    setUrl(e?.detail?.value ? e?.detail?.value : "")
                  }
                ></IonInput>
                <IonButton
                  size="large"
                  color="primary"
                  fill="clear"
                  onClick={() => {
                    if (!loading) {
                      getProduct();
                    }
                  }}
                >
                  {!loading && <IonIcon icon={send}></IonIcon>}
                  {loading && (
                    <IonSpinner
                      name={"crescent"}
                      color={"primary"}
                    ></IonSpinner>
                  )}
                </IonButton>
              </IonItem>
            </IonCol>
            <IonCol size="12">
              <div className={"ion-text-center"}>or</div>
            </IonCol>
            <IonCol>
              {product !== undefined && !loading ? (
                <IonCard>
                  <IonImg src={product.image}></IonImg>
                  <IonCardContent>
                    <IonCardHeader>
                      <IonCardTitle>
                        <IonInput
                          className={styles.name}
                          clearInput={true}
                          placeholder="Enter Product Name"
                          value={product.name}
                        ></IonInput>

                        <IonRow>
                          <IonCol size="6">
                            <IonRow>
                              <span className={styles.currency}>
                                {offer?.priceCurrency}
                              </span>
                              <IonInput
                                className={styles.price}
                                clearInput={true}
                                inputmode={"decimal"}
                                placeholder="Price"
                                value={
                                  offer?.price !== undefined
                                    ? offer.price.toString()
                                    : 0
                                }
                              ></IonInput>
                            </IonRow>
                          </IonCol>
                          <IonCol>
                            <IonInput
                              clearInput={true}
                              placeholder="SKU"
                              value={product.sku}
                            ></IonInput>

                            <div className={styles.rating}>
                              {Array.from(Array(rating).keys()).map((key) => {
                                return (
                                  <IonIcon key={"rating_" + key} icon={star} />
                                );
                              })}
                              <IonIcon icon={starHalf} />
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonCardTitle>

                      <IonCardSubtitle>
                        <IonTextarea
                          autoGrow={true}
                          value={product.description}
                          onIonChange={(e) => setDescription(e.detail.value!)}
                        ></IonTextarea>
                      </IonCardSubtitle>
                    </IonCardHeader>
                    <IonList>
                      <IonItem>
                        <IonLabel position="floating">Brand</IonLabel>
                        <IonInput
                          clearInput={true}
                          placeholder="Brand"
                          value={
                            product.schema?.brand !== undefined
                              ? product.schema.brand.toString()
                              : ""
                          }
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Seller</IonLabel>
                        <IonInput
                          clearInput={true}
                          placeholder="Seller"
                          value={
                            offer?.offeredBy !== undefined
                              ? offer.offeredBy.toString()
                              : ""
                          }
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="floating">Url</IonLabel>
                        <IonInput
                          clearInput={true}
                          type={"url"}
                          placeholder="Url"
                          value={product.url}
                        ></IonInput>
                      </IonItem>
                      <IonItem>
                        {tags?.map((keyword, index) => {
                          return (
                            <IonChip
                              key={"tag_" + index}
                              outline={true}
                              className={styles.tag}
                            >
                              <IonLabel>{keyword}</IonLabel>
                              <IonIcon icon={closeIcon}></IonIcon>
                            </IonChip>
                          );
                        })}
                      </IonItem>
                    </IonList>
                    <IonRow>
                      <IonCol size="6">
                        <IonButton
                          expand="block"
                          shape="round"
                          fill="outline"
                          onClick={close}
                        >
                          Cancel
                        </IonButton>
                      </IonCol>
                      <IonCol size="6">
                        <IonButton
                          fill="solid"
                          shape="round"
                          expand="block"
                          onClick={() => items.push(product)}
                        >
                          <IonIcon slot="start" icon={addOutline}></IonIcon> Add
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonCardContent>
                </IonCard>
              ) : null}

              {loading && (
                <IonList>
                  <IonListHeader>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80px" }}
                    ></IonSkeletonText>
                  </IonListHeader>
                  <IonItem>
                    <IonThumbnail slot="start">
                      <IonSkeletonText animated={true}></IonSkeletonText>
                    </IonThumbnail>
                    <IonLabel>
                      <h3>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "80%" }}
                        ></IonSkeletonText>
                      </h3>
                      <p>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "60%" }}
                        ></IonSkeletonText>
                      </p>
                      <p>
                        <IonSkeletonText
                          animated={true}
                          style={{ width: "30%" }}
                        ></IonSkeletonText>
                      </p>
                    </IonLabel>
                  </IonItem>
                </IonList>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
