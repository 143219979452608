import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { useStoreState } from "pullstate";
import { ListStore } from "store";
import { getLists } from "store/Selectors";
import { ListCard } from "components/ListCard";
import { useRef } from "react";
import { List } from "models/List";
import { Header } from "components/layout/Header";

import "./Home.scss";

const Home = () => {
  const pageRef = useRef<HTMLElement | undefined>(undefined);
  const itemLists: List[] = useStoreState(ListStore, getLists);

  return (
    <IonPage ref={pageRef}>
      <Header></Header>
      <IonContent fullscreen>
        <IonGrid className="ion-padding-start ion-padding-end extra-padding ion-padding-bottom ion-margin-bottom">
          <IonRow>
            <IonCol size="12">
              <IonText color="dark">
                <p className="title"></p>
              </IonText>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol size="12">
              {itemLists.map((list) => {
                return <ListCard key={list.id} list={list} pageRef={pageRef} />;
              })}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Home;
