import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonThumbnail,
  IonImg,
  IonSearchbar,
  IonIcon,
} from "@ionic/react";
import { createStore, get, set } from "data/Storage";
import { sunny, moon, personOutline } from "ionicons/icons";
import { useEffect, useState } from "react";

import "./Header.module.scss";

export const Header = () => {
  const [searchText, setSearchText] = useState("");
  const [theme, setTheme] = useState("");

  const setupTheme = async () => {
    await createStore();

    let theme: string = await get("theme");
    if (!theme) {
      const dark: boolean = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      theme = dark ? "dark" : "light";
    }

    updateTheme(theme);
  };

  const updateTheme = (theme: string) => {
    const dark = theme == "dark" ? true : false;
    document.body.classList.toggle("dark", dark);
    setTheme(theme);
    set("theme", theme);
  };

  useEffect(() => {
    setupTheme();
  }, []);

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonButton href="/">
            <IonThumbnail slot="start" className="logo">
              <IonImg src="/assets/icon/icon.png"></IonImg>
            </IonThumbnail>
          </IonButton>
        </IonButtons>

        <IonSearchbar
          className={"search"}
          value={searchText}
          onIonChange={(e) => setSearchText(e.detail.value!)}
          animated
        ></IonSearchbar>

        <IonButtons slot="end">
          <IonButton
            id="theme"
            onClick={() => updateTheme(theme == "dark" ? "light" : "dark")}
          >
            {theme == "dark" ? (
              <IonIcon icon={sunny} />
            ) : (
              <IonIcon icon={moon} />
            )}
          </IonButton>
          <IonButton>
            <IonIcon icon={personOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};
