import { Store } from "pullstate";
import { CategoriesState } from "store/Selectors";
import { categories as data } from "data/index";

const categories: CategoriesState = {
  categories: data(),
};
const CategoryStore = new Store(categories);

export default CategoryStore;
