import { createSelector } from "reselect";
import { Category } from "models/Category";
import { Product } from "models/Product";
import { List } from "models/List";

export interface ProductState {
  product: Product[];
}

export interface ListsState {
  itemLists: List[];
}

export interface CategoriesState {
  categories: Category[];
}

const getCategoriesState = (categoriesState: CategoriesState) =>
  categoriesState;
const getListsState = (itemListsState: ListsState) => itemListsState;
const getProductState = (productSate: ProductState) => productSate;

//  General getters
export const getAllProduct = createSelector(
  getProductState,
  (state) => state?.product
);
export const getCategories = createSelector(
  getCategoriesState,
  (state) => state?.categories
);
export const getLists = createSelector(
  getListsState,
  (state) => state.itemLists
);

//	Specific getters
export const getCategoryLists = (categoryId: number) =>
  createSelector(
    getListsState,
    (state) =>
      state?.itemLists?.filter(
        (itemList) => itemList.category.id === categoryId
      )[0]
  );
export const getList = (id: string) =>
  createSelector(
    getListsState,
    (state) => state?.itemLists?.filter((itemList) => itemList.id === id)[0]
  );
export const getCategory = (id: number) =>
  createSelector(
    getCategoriesState,
    (state) => state?.categories?.filter((category) => category.id === id)[0]
  );
