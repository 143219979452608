import { Store } from "pullstate";
import { ListsState } from "store/Selectors";
import { lists as data } from "data/index";

const lists: ListsState = {
  itemLists: data(),
};
const ListStore = new Store(lists);

export default ListStore;

// export const addList = details => {

// 	const newList = {
// 		id: Date.now(),
// 		title: details.title,
// 		date: details.date,
// 		time: details.time,
// 		products: details.products,
// 		audience: Math.random(),
// 		category_id: details.category_id
// 	};

// 	ListStore.update(s => { s.itemLists = [ ...s.itemLists, newList ] });
// }

// export const addScoreToPlayer = (scoreboardId, playerIndex) => {
// 	ProductStore.update(state => {

// 		const scoreboardIndex = state.scoreboards.findIndex(scoreboard => scoreboard.id === parseInt(scoreboardId));
// 		state.scoreboards[scoreboardIndex].players[playerIndex].score += 1;

// 		state.scoreboards[scoreboardIndex].players.sort((a, b) => {
// 			if (a.score > b.score) return -1
// 			return a.score < b.score ? 1 : 0
// 		});
// 	});
// }
