import { ChangeEvent, useState } from "react";
import { Error } from "models/form/Error";
import { Field } from "models/form/Field";

export const useFormInput = (initialValue = "") => {
  const [value, setValue] = useState(initialValue);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const tempValue = await e.currentTarget.value;
    setValue(tempValue);
  };

  return {
    value,
    reset: (newValue: string) => setValue(newValue),
    onIonChange: handleChange,
    onKeyUp: handleChange,
  };
};

export const validateForm = (fields: Field[]) => {
  const errors: Error[] = [];

  fields.forEach((field) => {
    if (field.required) {
      const fieldValue = field.input.state.value;

      if (fieldValue === "") {
        const error = {
          id: field.id,
          name: field.name !== undefined ? field.name : "",
          message: `Please check your ${field.id}`,
        };

        errors.push(error);
      }
    }
  });

  return errors;
};
