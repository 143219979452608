import {
  IonCardSubtitle,
  IonIcon,
  IonModal,
  IonRow,
  IonChip,
  IonActionSheet,
} from "@ionic/react";
import {
  bagHandle,
  pricetagsOutline,
  ellipsisVertical,
  trash,
  share,
  heart,
  close,
  pencil,
} from "ionicons/icons";
// import { useStoreState } from "pullstate";
import { MutableRefObject, useState } from "react";

// import { CategoryStore } from "store";
// import { getProduct } from "store/ProductStore";
// import { getCategory } from "store/Selectors";

import styles from "components/ListCard.module.scss";
import { ListModal } from "components/ListModal";
import { List } from "models/List";
// import { Product } from "models/Product";

interface Props {
  list: List;
  pageRef: MutableRefObject<HTMLElement | undefined>;
}

export const ListCard = ({ list, pageRef }: Props) => {
  // const itemListCategory = useStoreState(
  //   CategoryStore,
  //   getCategory(itemList?.category_id)
  // );
  // const [products, setProducts] = useState<Product[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showActionSheet, setShowActionSheet] = useState(false);

  // useEffect(() => {
  //   setProducts(getProduct({ amount: itemList.products }));
  // }, [itemList]);

  // const [ present, dismiss ] = useIonModal(ListModal, {

  //   dismiss: () => dismiss(),
  //   itemList,
  //   products,
  //   category: itemListCategory
  // });

  // const handleShowList = () => {

  //   console.log("in here");

  // 	present({

  // 	  // presentingElement: pageRef.current
  // 	});
  // }

  return (
    <>
      <div
        key={`list_${list.id}`}
        className={`${styles.itemListCard} ${
          list.state.pinned && styles.pinned
        }`}
      >
        <div className={styles.cardTitle}>
          <IonIcon
            color={list.state.pinned ? "primary" : "white"}
            icon={bagHandle}
          />

          <IonCardSubtitle color={list.state.pinned ? "light" : "primary"}>
            {list.category.name}
          </IonCardSubtitle>

          <div
            className={styles.actions}
            onClick={() => setShowActionSheet(true)}
          >
            <IonIcon
              color={list.state.pinned ? "primary" : "white"}
              icon={ellipsisVertical}
            />
          </div>
        </div>

        <div className={styles.itemListTitle}>
          <h3>{list.title}</h3>
        </div>

        <IonRow className={styles.products}>
          {list.products.map((product, index) => {
            return (
              <div
                key={`product_${index}`}
                className={styles.product}
                onClick={() => setShowModal(true)}
              >
                <img src={product.image} alt="avatar" />
              </div>
            );
          })}
        </IonRow>

        <div className={styles.itemListDetails}>
          <div className={styles.detailCount}>
            {list.products.map((product) => {
              if (product?.keywords !== undefined) {
                const tags = product?.keywords.split(",");
                return tags.map((keyword) => {
                  return (
                    <IonChip outline={true} className={styles.tag}>
                      {keyword}
                    </IonChip>
                  );
                });
              } else {
                return <></>;
              }
            })}
          </div>

          <div className={styles.detailCount}>
            <IonIcon
              icon={pricetagsOutline}
              color={list.state.pinned ? "light" : "primary"}
            />
            <span>
              {list.products.length}{" "}
              <span className={"hide-xs hide-sm"}>Products</span>
            </span>
          </div>
        </div>
      </div>

      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        presentingElement={pageRef.current}
      >
        <ListModal dismiss={() => setShowModal(false)} list={list} />
      </IonModal>

      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass="list-action"
        buttons={[
          {
            text: "Edit",
            role: "destructive",
            icon: pencil,
            id: "edit-button",
            data: {
              type: "edit",
            },
            handler: () => {
              console.log("Edit clicked");
            },
          },
          {
            text: "Delete",
            role: "destructive",
            icon: trash,
            id: "delete-button",
            data: {
              type: "delete",
            },
            handler: () => {
              console.log("Delete clicked");
            },
          },
          {
            text: "Share",
            icon: share,
            data: 10,
            handler: () => {
              console.log("Share clicked");
            },
          },
          {
            text: "Favorite",
            icon: heart,
            handler: () => {
              console.log("Favorite clicked");
            },
          },
          {
            text: "Cancel",
            icon: close,
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ]}
      ></IonActionSheet>
    </>
  );
};
