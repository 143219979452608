import { useFormInput } from "utils/Validator";

export const useCreateListFields = () => {
  return [
    {
      id: "name",
      label: "Name",
      required: true,
      input: {
        props: {
          type: "text",
          placeholder: "Your shopping list name",
        },
        state: useFormInput(""),
      },
    },
    {
      id: "category",
      label: "Category",
      required: true,
      input: {
        props: {
          type: "text",
          placeholder: "Grocery",
        },
        state: useFormInput(""),
      },
    },
  ];
};
