import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import {
  addCircle,
  addCircleOutline,
  home,
  homeOutline,
  notifications,
  notificationsOutline,
  person,
  personOutline,
  search,
  searchOutline,
} from "ionicons/icons";

// import Home from './pages/Home';
import Home from "pages/Home";
import Tab2 from "./pages/Tab2";
import New from "pages/New";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.scss";
import "./theme/action-bar.scss";
import "./app.scss";

import { useState } from "react";

setupIonicReact();

const App: React.FC = () => {
  const tabs = [
    {
      name: "Home",
      url: "/home",
      activeIcon: home,
      icon: homeOutline,
      component: Home,
    },
    {
      name: "Search",
      url: "/search",
      activeIcon: search,
      icon: searchOutline,
      component: Tab2,
    },
    {
      name: "Add",
      url: "/new",
      activeIcon: addCircle,
      icon: addCircleOutline,
      component: New,
    },
    {
      name: "Account",
      url: "/account",
      activeIcon: person,
      icon: personOutline,
      component: Tab2,
    },
    {
      name: "Notifications",
      url: "/notifications",
      activeIcon: notifications,
      icon: notificationsOutline,
      component: Tab2,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs onIonTabsDidChange={(e) => setActiveTab(e.detail.tab)}>
          <IonRouterOutlet>
            {tabs.map((tab, index) => {
              return (
                <Route key={index} exact path={tab.url}>
                  <tab.component />
                </Route>
              );
            })}
            <Redirect exact from="/" to="/home" />
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            {tabs.map((tab, barIndex) => {
              const active = tab.name === activeTab;

              return (
                <IonTabButton
                  key={`tab_${barIndex}`}
                  tab={tab.name}
                  href={tab.url}
                >
                  <IonIcon icon={active ? tab.activeIcon : tab.icon} />
                </IonTabButton>
              );
            })}
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
