import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonModal,
  IonPage,
  IonRow,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { Product } from "models/Product";

import "./New.scss";

import CustomField from "components/form/CustomField";
import { AddProductModal } from "components/AddProductModal";
import { useCreateListFields } from "data/form/fields";
import { useEffect, useRef, useState } from "react";
import { validateForm } from "utils/Validator";
import { useParams } from "react-router";
import { Header } from "components/layout/Header";

const New = () => {
  const [showModal, setShowModal] = useState(false);

  const pageRef = useRef<HTMLElement | undefined>(undefined);
  const history = useHistory();

  const params = useParams();
  const fields = useCreateListFields();
  const [items, setItems] = useState<Product[]>([]);
  const [errors, setErrors] = useState<Error[]>([]);

  const save = () => {
    const errors = validateForm(fields);
    setErrors(errors);

    if (!errors.length) {
      //  Submit your form here
    }
  };

  const cancel = () => {
    history.push("/home");
  };

  useEffect(() => {
    return () => {
      fields.forEach((field) => field.input.state.reset(""));
      setErrors([]);
    };
  }, [params]);

  return (
    <IonPage className={"create-form"}>
      <Header />
      <IonContent fullscreen>
        <IonGrid className="ion-padding">
          <IonRow className="ion-padding-top">
            <IonCol size="12">
              {fields.map((field, index) => {
                return (
                  <CustomField
                    field={field}
                    key={`field_${index}`}
                    errors={errors}
                  />
                );
              })}
            </IonCol>
            <IonCol size="12">
              <IonButton
                expand="block"
                shape="round"
                fill="outline"
                onClick={() => setShowModal(true)}
              >
                Add Product
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      <IonFooter>
        <IonGrid className="ion-padding-bottom ion-margin-bottom">
          <IonRow>
            <IonCol size="6">
              <IonButton
                expand="block"
                shape="round"
                fill="outline"
                onClick={cancel}
              >
                Cancel
              </IonButton>
            </IonCol>
            <IonCol size="6">
              <IonButton expand="block" shape="round" onClick={save}>
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonFooter>

      <IonModal
        isOpen={showModal}
        onDidDismiss={() => setShowModal(false)}
        presentingElement={pageRef.current}
      >
        <AddProductModal close={() => setShowModal(false)} items={items} />
      </IonModal>
    </IonPage>
  );
};

export default New;
