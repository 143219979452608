import { Store } from "pullstate";
import { Product } from "models/Product";
import { products as data } from "data/index";
import { ProductState } from "store/Selectors";

const products: ProductState = {
  product: data(),
};
const ProductStore = new Store(products);

export default ProductStore;

interface Props {
  amount: number;
}

export const getProduct = ({ amount }: Props) => {
  const tempProduct: Product[] = [...ProductStore.getRawState().product];
  return tempProduct.sort(() => Math.random() - Math.random()).slice(0, amount);
};

// export const markActiveAsDone = () => {

// 	ProductStore.update(state => {

// 		const scoreboardIndex = state.scoreboards.findIndex(scoreboard => scoreboard.active === true);
// 		state.scoreboards[scoreboardIndex].done = true;
// 	});
// }

// export const addScoreboard = (players, details) => {

// 	ProductStore.update(s => { s.scoreboards = s.scoreboards.map(scoreboard => scoreboard.active = false) });

// 	ProductStore.update(state => {

// 		state.scoreboards.forEach((scoreboard, index) => {

// 			state.scoreboards[index].active = false;
// 		});
// 	});

// 	const newScoreboard = {

// 		id: Date.now(),
// 		title: details.title,
// 		players: [ ...players ],
// 		active: true
// 	};

// 	const playersToSave = players.filter(p => p.saved === true);

// 	ProductStore.update(s => { s.scoreboards = [ ...s.scoreboards, newScoreboard ] });
// 	ProductStore.update(s => { s.players = [ ...s.players, ...playersToSave ] });
// }

// export const addScoreToPlayer = (scoreboardId, playerIndex) => {

// 	ProductStore.update(state => {

// 		const scoreboardIndex = state.scoreboards.findIndex(scoreboard => scoreboard.id === parseInt(scoreboardId));
// 		state.scoreboards[scoreboardIndex].players[playerIndex].score += 1;

// 		state.scoreboards[scoreboardIndex].players.sort((a, b) => {
// 			if (a.score > b.score) return -1
// 			return a.score < b.score ? 1 : 0
// 		});
// 	});
// }
